<template>
  <v-card
    v-if="value != null"
    elevation="2"
    :class="{
      'contract-phase-record-card-no-border': noBorder,
      'contract-phase-record-card-valid-choice': validChoice,
    }"
    class="contract-phase-record-card d-flex align-center justify-space-between flex-row py-2 px-3"
    style="gap: 1rem"
    :style="{
      width: fullWidth ? '100%' : 'auto',
    }"
    rounded="lg"
  >
    <div class="d-flex flex-column align-start" style="gap: 0.5rem">
      <h4>
        <i class="fad fa-folder-open mr-1 secondary--text"></i>
        <a
          small
          text
          target="_blank"
          class="py-0 px-1 fs-13px secondary--text"
          max-height="20px"
          :href="`/projects/${value.project.id}`"
          >{{ value.project.fullName }}
        </a>
      </h4>
      <h4 class="d-flex align-center">
        <i class="fad fa-calendar-lines mr-2 secondary--text" style="width: 16px"></i>
        <v-chip color="secondary" dark class="px-1" label x-small>
          <span class="fs-13px font-weight-medium">{{ value.startDate.substr(0, 7) }}</span>
        </v-chip>
      </h4>
    </div>
    <div class="d-flex flex-row align-center" style="gap: 1rem">
      <div class="d-flex flex-column px-2 rounded" style="background: rgba(244, 67, 54, 0.08)">
        <b class="blue-grey--text" style="white-space: nowrap"
          ><i class="fad fa-arrow-up-from-arc mr-2"></i>Source</b
        >
        <div class="d-flex flex-row" style="gap: 0.75rem">
          <div class="d-flex flex-column" style="gap: 3px; width: 125px">
            <b class="blue-grey--text opacity-64" style="white-space: nowrap">Labor</b>
            <b class="opacity-87 fs-12px" style="white-space: nowrap">{{
              getLaborTypeObj(value.sourceLaborType).desc
            }}</b>
          </div>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-end pr-1" style="gap: 3px">
            <b class="blue-grey--text opacity-64" style="white-space: nowrap">Sell Rate</b>
            <span class="d-flex align-center" style="gap: 0.35rem">
              <span class="mono-font font-weight-bold fs-12px">
                {{ sourceSellRate | usdFormatNoDecimal }} /hr
              </span>
            </span>
          </div>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column pr-1" style="gap: 3px">
            <b class="blue-grey--text opacity-64" style="white-space: nowrap">Offers</b>
            <span class="d-flex align-center" style="gap: 0.35rem">
              <span
                style="line-height: 0"
                class="red--text text--darken-1 fs-14px font-weight-medium font-weight-medium mono-font"
                >{{ value.offeredHours | fixedFloatFormat }}
              </span>
              <span class="fs-12px font-weight-medium opacity-64">hours</span>
            </span>
          </div>
        </div>
      </div>
      <!-- f1f9f1 vs -->
      <div class="d-flex flex-column px-2 rounded" style="background: rgba(76, 175, 80, 0.08)">
        <b class="blue-grey--text" style="white-space: nowrap"
          ><i class="fad fa-arrow-down-to-arc mr-2"></i>Target</b
        >
        <div class="d-flex flex-row" style="gap: 0.75rem">
          <div class="d-flex flex-column" style="gap: 3px; width: 125px">
            <b class="blue-grey--text opacity-64" style="white-space: nowrap">Labor</b>
            <b class="opacity-87 fs-12px" style="white-space: nowrap">{{
              getLaborTypeObj(value.targetLaborType).desc
            }}</b>
          </div>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-end pr-1" style="gap: 3px">
            <b class="blue-grey--text opacity-64" style="white-space: nowrap">Sell Rate</b>
            <span class="d-flex align-center" style="gap: 0.35rem">
              <span class="mono-font font-weight-bold fs-12px">
                {{ targetSellRate | usdFormatNoDecimal }} /hr
              </span>
            </span>
          </div>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column pr-1" style="gap: 3px">
            <b class="blue-grey--text opacity-64" style="white-space: nowrap">Receives</b>
            <span class="d-flex align-center" style="gap: 0.35rem">
              <span
                style="line-height: 0"
                class="green--text text--darken-1 fs-14px font-weight-medium font-weight-medium mono-font"
                >{{ receivedHours | fixedFloatFormat }}
              </span>
              <span class="fs-12px font-weight-medium opacity-64">hours</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import enums from "../../../plugins/enums";

export default {
  name: "offer-record-review-card",
  components: {},
  data() {
    return {
      enums,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    sourceSellRate: {
      type: Number,
      required: true,
    },
    targetSellRate: {
      type: Number,
      required: true,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    validChoice() {
      if (this.value.offeredHours != 0) return true;
      return false;
    },
    receivedHours() {
      return (this.value.offeredHours * this.sourceSellRate) / this.targetSellRate;
    },
  },
  mounted() {},
  methods: {
    getLaborTypeObj(val) {
      return this.getEnumMember(enums.LABOR_TYPE, val);
    },
  },
};
</script>

<style lang="scss"></style>
