<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 500 : 1100"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    class="slideout-deep-purple-header"
    :visible.sync="slideouts.review.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Offer ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1 d-flex align-center" small>
        <span><i class="fad fa-horse-head mr-2"></i>Review: {{ selected.name }}</span>
        <entity-id class="ml-2" :label="selected.id" dark disable-link title="Offer"></entity-id>
        <resource-offer-state class="ml-2" :state="selected.state"></resource-offer-state>
      </h3>
      <div>
        <panel-size-control dark v-model="slideouts.review.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon dark>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <div
      color="white"
      style="position: sticky; top: 0; z-index: 2"
      @wheel="wheelIt"
      ref="tabsContainer"
    >
      <v-tabs
        v-model="slideouts.review.tab"
        color="error"
        v-if="slideouts.review.active && !isFetching"
      >
        <v-tab :key="0"> <i class="fad fa-memo-circle-info mr-2"></i> Basic Info </v-tab>
        <v-tab :key="1">
          <i class="fad fa-clock mr-2"></i>Offered Hours
          <v-chip
            :color="slideouts.review.tab == 1 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ totalOfferedHours }}
          </v-chip>
        </v-tab>
        <v-tab :key="2"> <i class="fad fa-usd-circle mr-2"></i>Labor Sell Rates</v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Offer ...</p>
    </v-container>

    <v-tabs-items v-else v-model="slideouts.review.tab" style="height: 100%">
      <v-tab-item :key="0">
        <v-container fluid class="pa-4">
          <v-row dense>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field-alt
                :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
                label="Name"
                placeholder="Offer Name"
                v-model="selected.name"
                readonly
              ></v-text-field-alt>
            </v-col>
            <v-col cols="12" md="12" class="mb-4">
              <rich-text-editor
                ref="richTextEditor"
                v-model="selected.justification"
                title="Offer Justification<i class='fas fa-star-of-life pink--text label-icon'></i>"
                showLabel
                allowExpand
                readonly
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item :key="1" style="height: 100%">
        <v-container fluid class="pa-4" style="height: 100%">
          <div style="height: 100%; display: flex; flex-direction: column">
            <v-row class="mt-0">
              <v-col style="width: 100%" class="pt-0">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-up-from-arc mr-2"></i>Take hours from
                  <span class="opacity-64 fs-12px ml-1">"Source"</span>
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  readonly
                  :isUsersLoading="isUsersLoading"
                  :value="selected.sourceUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
              </v-col>
              <v-col style="width: 100%" class="pt-0">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-down-to-arc mr-2"></i>Offer hours to
                  <span class="opacity-64 fs-12px ml-1">"Target"</span>
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  readonly
                  :isUsersLoading="isUsersLoading"
                  :value="selected.targetUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
              </v-col>
            </v-row>
            <!-- <v-divider class="mt-4"></v-divider> -->
            <div class="mt-6 mb-0 d-flex flex-row align-center" style="gap: 1rem">
              <label class="input-label">
                <i class="fad fa-clock mr-2"></i>Offered Hours
                <v-chip
                  color="secondary"
                  dark
                  class="ml-2 font-weight-bold"
                  small
                  label
                  style="height: 20px"
                  >{{ totalOfferedHours }}
                </v-chip>
              </label>
            </div>
            <div
              class="pa-5 inset-shadow mt-2"
              style="
                background: #eceff1;
                border-radius: 0.5rem;
                overflow: auto;
                overflow: overlay;
                height: 100%;
              "
            >
              <v-slide-y-reverse-transition mode="out-in" style="width: 100%">
                <div
                  v-if="selected.offerRecords != null && selected.offerRecords.length == 0"
                  key="empty"
                  style="gap: 1rem"
                  class="d-flex align-center justify-center flex-column"
                >
                  <i class="fad fa-horse-saddle fa-swap-opacity" style="font-size: 7rem"></i>
                  <h3 class="mb-1">Nothing to offer yet!</h3>
                </div>
                <v-slide-y-transition
                  v-else
                  key="not-empty"
                  mode="out-in"
                  group
                  style="width: 100%; gap: 1rem"
                  class="d-flex flex-column"
                >
                  <offer-record-review-card
                    v-for="(offerRecord, i) in selected.offerRecords"
                    :key="'or_' + i"
                    :value="selected.offerRecords[i]"
                    readonly
                    :sourceSellRate="sourceSellRateByLaborType(offerRecord.sourceLaborType)"
                    :targetSellRate="targetSellRateByLaborType(offerRecord.targetLaborType)"
                    @delete="deleteOfferRecord(offerRecord)"
                    fullWidth
                  ></offer-record-review-card>
                </v-slide-y-transition>
              </v-slide-y-reverse-transition>
            </div>
          </div>
        </v-container>
      </v-tab-item>
      <v-tab-item :key="2" style="height: 100%">
        <v-container fluid class="pa-4" style="height: 100%">
          <div style="height: 100%; display: flex; flex-direction: column; flex: none">
            <v-row class="mt-0" style="flex: none">
              <v-col style="width: 100%; height: 100%" class="pt-0 d-flex flex-column">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-up-from-arc mr-2"></i>Source Labor Rates
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  readonly
                  :isUsersLoading="isUsersLoading"
                  :value="selected.sourceUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
                <div
                  class="pa-5 inset-shadow mt-2 d-flex flex-column"
                  style="background: #eceff1; border-radius: 0.5rem; gap: 0.5rem"
                >
                  <v-card
                    elevation="2"
                    rounded="lg"
                    class="py-2 px-3"
                    v-for="(labor, i) in sourceSellRates.laborRatePerLaborTypes"
                    :key="i"
                  >
                    <v-container class="pa-0">
                      <v-row dense>
                        <v-col cols="12" md="5" class="d-flex flex-column">
                          <b class="blue-grey--text fs-12px" style="white-space: nowrap"> Labor </b>
                          <b class="opacity-87 fs-14px" style="white-space: nowrap">
                            {{ getLaborTypeObj(labor.laborType).desc }}
                          </b>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" md="7" class="d-flex flex-column pl-3">
                          <b class="blue-grey--text fs-12px" style="white-space: nowrap">
                            Sell Rate
                          </b>
                          <!-- <span class="info--text mono-font fs-14px">
                            {{ labor.laborRate | usdFormat }} /hr
                          </span>
                          {{ sourceRatesFilteredAndGroupedOptions(labor.laborType) }} -->
                          <div class="d-flex">
                            <v-text-field-alt
                              :rules="[allRules.number, allRules.requiredNumber]"
                              v-model.number="labor.laborRate"
                              prefix="$"
                              suffix="/hr"
                              style="width: 120px"
                              hide-details
                              short
                            >
                            </v-text-field-alt>

                            <v-menu dense offset-y left z-index="1000" max-height="250px">
                              <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                  color="secondary"
                                  small
                                  dark
                                  width="120px"
                                  class="px-2 ml-4"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <i class="fas fa-usd mr-2"></i> References
                                  <i class="fas fa-caret-down ml-3 mr-1"></i>
                                </v-btn>
                              </template>
                              <v-list dense class="sell-rate-refs-menu-list">
                                <v-list-item
                                  v-for="(optionItem, i) in sourceRatesFilteredAndGroupedOptions(
                                    labor.laborType
                                  )"
                                  @click="() => (labor.laborRate = optionItem.sellRate)"
                                  :key="i"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title color="secondary" class="mb-2">
                                      <span class="mono-font fs-14px">
                                        {{ optionItem.sellRate | usdFormat }}
                                        <span class="opacity-54">/hr</span>
                                      </span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle color="secondary">
                                      <div class="d-flex flex-column" style="gap: 0.25rem">
                                        <span
                                          v-for="(ref, refIndex) in optionItem.refs"
                                          :key="refIndex"
                                        >
                                          <span v-if="ref.refType == 'DefaultUserRate'">
                                            <i class="fad fa-user-gear mr-1"></i> Default User Rate
                                          </span>
                                          <span v-else-if="ref.refType == 'ContractUserRate'">
                                            <i class="fad fa-file-signature mr-1"></i>

                                            <code
                                              class="mono-font blue-grey--text text--lighten-1 fs-10px blue-grey lighten-5 px-0 mr-1"
                                              style="flex: none"
                                            >
                                              #{{ ref.id }}
                                            </code>
                                            {{ ref.name }}
                                          </span>
                                        </span>
                                      </div>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col style="width: 100%; height: 100%" class="pt-0 d-flex flex-column">
                <label class="input-label mt-2" style="margin-bottom: 6px">
                  <i class="fad fa-arrow-down-to-arc mr-2"></i>Target Labor Rates
                </label>
                <user-all-selector
                  :users="users"
                  required
                  hideLabel
                  hideDetails
                  readonly
                  :isUsersLoading="isUsersLoading"
                  :value="selected.targetUserId"
                  style="width: 100%"
                  class="ml-0 white"
                ></user-all-selector>
                <div
                  class="pa-5 inset-shadow mt-2 d-flex flex-column"
                  style="background: #eceff1; border-radius: 0.5rem; gap: 0.5rem"
                >
                  <v-card
                    elevation="2"
                    rounded="lg"
                    class="py-2 px-3"
                    v-for="(labor, i) in targetSellRates.laborRatePerLaborTypes"
                    :key="i"
                  >
                    <v-container class="pa-0">
                      <v-row dense>
                        <v-col cols="12" md="5" class="d-flex flex-column">
                          <b class="blue-grey--text fs-12px" style="white-space: nowrap"> Labor </b>
                          <b class="opacity-87 fs-14px" style="white-space: nowrap">
                            {{ getLaborTypeObj(labor.laborType).desc }}
                          </b>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" md="7" class="d-flex flex-column pl-3">
                          <b class="blue-grey--text fs-12px" style="white-space: nowrap">
                            Sell Rate
                          </b>
                          <!-- <span class="info--text mono-font fs-14px">
                            {{ labor.laborRate | usdFormat }} /hr
                          </span>
                          {{ targetRatesFilteredAndGroupedOptions(labor.laborType) }} -->
                          <div class="d-flex">
                            <v-text-field-alt
                              :rules="[allRules.number, allRules.requiredNumber]"
                              v-model.number="labor.laborRate"
                              prefix="$"
                              suffix="/hr"
                              style="width: 120px"
                              hide-details
                              short
                            >
                            </v-text-field-alt>

                            <v-menu dense offset-y left z-index="1000">
                              <template v-slot:activator="{ attrs, on }">
                                <v-btn
                                  color="secondary"
                                  small
                                  dark
                                  width="120px"
                                  class="px-2 ml-4"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <i class="fas fa-usd mr-2"></i> References
                                  <i class="fas fa-caret-down ml-3 mr-1"></i>
                                </v-btn>
                              </template>
                              <v-list dense class="sell-rate-refs-menu-list">
                                <v-list-item
                                  v-for="(optionItem, i) in targetRatesFilteredAndGroupedOptions(
                                    labor.laborType
                                  )"
                                  @click="() => (labor.laborRate = optionItem.sellRate)"
                                  :key="i"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title color="secondary" class="mb-2">
                                      <span class="mono-font fs-14px">
                                        {{ optionItem.sellRate | usdFormat }}
                                        <span class="opacity-54">/hr</span>
                                      </span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle color="secondary">
                                      <div class="d-flex flex-column" style="gap: 0.25rem">
                                        <span
                                          v-for="(ref, refIndex) in optionItem.refs"
                                          :key="refIndex"
                                        >
                                          <span v-if="ref.refType == 'DefaultUserRate'">
                                            <i class="fad fa-user-gear mr-1"></i> Default User Rate
                                          </span>
                                          <span v-else-if="ref.refType == 'ContractUserRate'">
                                            <i class="fad fa-file-signature mr-1"></i>

                                            <code
                                              class="mono-font blue-grey--text text--lighten-1 fs-10px blue-grey lighten-5 px-0 mr-1"
                                              style="flex: none"
                                            >
                                              #{{ ref.id }}
                                            </code>
                                            {{ ref.name }}
                                          </span>
                                        </span>
                                      </div>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      v-model="modals.approve.active"
      max-width="800px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <span class="fs-16px">
            <i class="fad fa-check-double mr-2"></i>Approve Offer: <b>{{ selected.name }}</b>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.approve.valid" ref="approveOfferForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.approve.note"
                    title="Approval Note<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                    showLabel
                    allowExpand
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="approveOfferDiscard()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="cyan"
              :dark="validApproveNotes && !modals.approve.loading"
              :disabled="!validApproveNotes || modals.approve.loading"
              :loading="modals.approve.loading"
              @click="approveOfferConfirmed()"
            >
              <i class="far fa-check-double mr-2"></i>
              Confirm Approval
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.reject.active" max-width="800px" persistent style="z-index: 99999999">
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <span class="fs-16px">
            <i class="fad fa-power-off mr-2"></i>Reject Offer: <b>{{ selected.name }}</b>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.reject.valid" ref="rejectOfferForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.reject.note"
                    title="Rejection Note<i class='fas fa-star-of-life pink--text label-icon' style='line-height: 20px'></i>"
                    showLabel
                    allowExpand
                  />
                  <input type="text" disabled readonly style="display: none" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="rejectOfferDiscard()">
              <i class="fal fa-xmark mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="pink"
              :dark="validRejectNotes && !modals.reject.loading"
              :disabled="!validRejectNotes || modals.reject.loading"
              :loading="modals.reject.loading"
              @click="rejectOfferConfirmed()"
            >
              <i class="far fa-power-off mr-2"></i>
              Confirm Rejection
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="d-flex ml-2" v-if="!isFetching && $has(perms.ResourceOffers.Update)">
          <div class="d-flex ml-2" v-if="!isFetching && canReject">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="pink"
                  dark
                  :loading="slideouts.review.isRejecting"
                  :disabled="slideouts.review.isLoading"
                  @click="rejectOffer"
                >
                  <i class="far fa-power-off mr-2"></i>
                  Reject Offer
                </v-btn>
              </template>
              <span class="d-flex align-center"> Reject offer </span>
            </v-tooltip>
          </div>
          <div class="d-flex ml-2" v-if="!isFetching && canApprove">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="cyan"
                  dark
                  :loading="slideouts.review.isApproving"
                  :disabled="slideouts.review.isLoading"
                  @click="approveOffer"
                >
                  <i class="far fa-check-double mr-2"></i>
                  Approve Offer
                </v-btn>
              </template>
              <span class="d-flex align-center">
                Approve offer and proceed to shift the hours!
              </span>
            </v-tooltip>
          </div>

          <v-divider vertical class="mx-2"></v-divider>

          <div class="d-flex ml-2" v-if="selected.id != null">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="2"
                  width="32px"
                  height="32px"
                  min-width="32px"
                  min-height="32px"
                  :loading="isFetching"
                  :disabled="slideouts.review.isLoading || isFetching"
                  @click="fetchOffer()"
                >
                  <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
                </v-btn>
              </template>
              <span>Refetch Offer</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import offersAPI from "../services/resource-offers-service";
import ResourceOfferState from "./ResourceOfferState.vue";
import OfferRecordReviewCard from "./OfferRecordReviewCard.vue";
import usersAPI from "../../Admin/services/StaffService";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import enums from "../../../plugins/enums";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";
import { uniqBy } from "lodash";

export default {
  name: "review-offer",
  components: {
    PanelSizeControl,
    UserAllSelector,
    OfferRecordReviewCard,
    ResourceOfferState,
    RichTextEditor,
  },
  data() {
    return {
      perms,
      enums,
      offerId: null,
      isFetching: false,
      isUsersLoading: false,
      users: [],
      selected: {},
      selectedCemented: {},
      slideouts: {
        review: {
          tab: 0,
          active: false,
          fullWidth: false,
          isLoading: false,
          isAccepting: false,
          isDeclining: false,
          isApproving: false,
          isRejecting: false,
          isClosing: false,
        },
      },
      modals: {
        reject: {
          active: false,
          valid: false,
          loading: false,
          note: null,
        },
        approve: {
          active: false,
          valid: false,
          loading: false,
          note: null,
        },
      },
      sourceSellRates: {
        laborRatePerLaborTypes: [], // float LaborRate, LaborTypeEnum LaborType
      },
      targetSellRates: {
        laborRatePerLaborTypes: [], // float LaborRate, LaborTypeEnum LaborType
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.review.fullWidth ? "100%" : this.nested ? "87%" : "1100px";
    },
    offersUrl() {
      if (this.selected == null) return "";
      return `offers/${this.selected.id}`;
    },
    totalOfferedHours() {
      if (!this.slideouts.review.active || this.isFetching) return 0;
      else
        return this.selected.offerRecords.reduce((total, cur) => {
          return total + cur.offeredHours;
        }, 0);
    },
    canApprove() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.$has(perms.ResourceOffers.Approve) &&
        this.selected.state == enums.ResourceOfferState.PendingManagement.value
      );
    },
    canReject() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.$has(perms.ResourceOffers.Reject) &&
        this.selected.state == enums.ResourceOfferState.PendingManagement.value
      );
    },
    validApproveNotes() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.modals.approve.note != null &&
        this.modals.approve.note.trim() != "<p></p>" &&
        this.modals.approve.note.trim() != ""
      );
    },
    validRejectNotes() {
      if (!this.slideouts.review.active || this.isFetching) return false;
      return (
        this.modals.reject.note != null &&
        this.modals.reject.note.trim() != "<p></p>" &&
        this.modals.reject.note.trim() != ""
      );
    },
    sourceRatesAllOptions() {
      if (this.selected == null || !this.slideouts.review.active) return [];
      let list = [];
      list = this.selected.assignedContracts
        .filter((c) => c.sourceSellRates != null)
        .map((c) => {
          return {
            type: "ContractUserRate",
            contract: {
              id: c.id,
              name: c.name,
              isLocked: c.isLocked,
              type: c.type,
              origin: c.origin,
              status: c.status,
              project: null,
            },
            sellRates: this.cloneDeep(c.sourceSellRates),
          };
        });
      list.unshift({
        sellRates: this.cloneDeep(this.selected.sourceUserDefaultSellRates),
        type: "DefaultUserRate",
      });
      return list;
    },
    sourceRatesFilteredOptions() {
      return (laborType) =>
        this.sourceRatesAllOptions.map((option) => {
          return option.type == "ContractUserRate"
            ? {
                sellRate: option.sellRates[this.getLaborPropByLaborEnum(laborType)],
                type: option.type,
                contract: option.contract,
              }
            : {
                sellRate: option.sellRates[this.getLaborPropByLaborEnum(laborType)],
                type: option.type,
              };
        });
    },
    sourceRatesFilteredAndGroupedOptions() {
      return (laborType) => {
        if (this.selected == null || !this.slideouts.review.active) return [];
        let originalList = this.sourceRatesFilteredOptions(laborType);
        let list = [
          {
            sellRate: originalList[0].sellRate,
            refs: [
              {
                refType: "DefaultUserRate",
              },
            ],
          },
        ];
        for (let i = 1; i < originalList.length; i++) {
          const element = originalList[i];
          let foundIndex = list.findIndex((l) => l.sellRate == element.sellRate);
          if (foundIndex != -1) {
            //existing sell rate
            if (list[foundIndex].refs == null) list[foundIndex].refs = [];
            list[foundIndex].refs.push({ refType: "ContractUserRate", ...element.contract });
          } else {
            //new sell rate
            list.push({
              sellRate: element.sellRate,
              refs: [{ refType: "ContractUserRate", ...element.contract }],
            });
          }
        }
        return list;
      };
    },
    sourceSellRateByLaborType() {
      return (laborType) => {
        return this.sourceSellRates.laborRatePerLaborTypes.find((l) => l.laborType == laborType)
          ?.laborRate;
      };
    },
    targetRatesAllOptions() {
      if (this.selected == null || !this.slideouts.review.active) return [];
      let list = [];
      list = this.selected.assignedContracts
        .filter((c) => c.targetSellRates != null)
        .map((c) => {
          return {
            type: "ContractUserRate",
            contract: {
              id: c.id,
              name: c.name,
              isLocked: c.isLocked,
              type: c.type,
              origin: c.origin,
              status: c.status,
              project: null,
            },
            sellRates: this.cloneDeep(c.targetSellRates),
          };
        });
      list.unshift({
        sellRates: this.cloneDeep(this.selected.targetUserDefaultSellRates),
        type: "DefaultUserRate",
      });
      return list;
    },
    targetRatesFilteredOptions() {
      return (laborType) =>
        this.targetRatesAllOptions.map((option) => {
          return option.type == "ContractUserRate"
            ? {
                sellRate: option.sellRates[this.getLaborPropByLaborEnum(laborType)],
                type: option.type,
                contract: option.contract,
              }
            : {
                sellRate: option.sellRates[this.getLaborPropByLaborEnum(laborType)],
                type: option.type,
              };
        });
    },
    targetRatesFilteredAndGroupedOptions() {
      return (laborType) => {
        if (this.selected == null || !this.slideouts.review.active) return [];
        let originalList = this.targetRatesFilteredOptions(laborType);
        let list = [
          {
            sellRate: originalList[0].sellRate,
            refs: [
              {
                refType: "DefaultUserRate",
              },
            ],
          },
        ];
        for (let i = 1; i < originalList.length; i++) {
          const element = originalList[i];
          let foundIndex = list.findIndex((l) => l.sellRate == element.sellRate);
          if (foundIndex != -1) {
            //existing sell rate
            if (list[foundIndex].refs == null) list[foundIndex].refs = [];
            list[foundIndex].refs.push({ refType: "ContractUserRate", ...element.contract });
          } else {
            //new sell rate
            list.push({
              sellRate: element.sellRate,
              refs: [{ refType: "ContractUserRate", ...element.contract }],
            });
          }
        }
        return list;
      };
    },
    targetSellRateByLaborType() {
      return (laborType) => {
        return this.targetSellRates.laborRatePerLaborTypes.find((l) => l.laborType == laborType)
          ?.laborRate;
      };
    },
  },
  created() {
    this.getAllUsersData();
  },
  methods: {
    getLaborTypeObj(val) {
      return this.getEnumMember(enums.LABOR_TYPE, val);
    },
    wheelIt(evt) {
      evt.preventDefault();
      // this.$log("wheel", evt);
      if (evt.deltaY > 0) {
        // this.$log("<<<< left");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__prev"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__prev").click();
      } else {
        // this.$log(">>>> right");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__next"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__next").click();
      }
      // this.$log("|||| scrollLeft", this.$refs.tabsContainer.scrollLeft);
      evt.stopPropagation();
    },
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    onSlideoutClosing(e) {
      //reset the changes
      this.selected = {};
      this.$emit("close");
    },
    closeUpdateSlideout() {
      this.slideouts.review.active = false;
    },
    open(id) {
      this.$log(">>>>>>> open", id);
      this.offerId = id;
      this.fetchOffer();
      this.slideouts.review.tab = 1;
      this.slideouts.review.active = true;
    },
    prepareOfferReview() {
      this.sourceSellRates.laborRatePerLaborTypes = uniqBy(
        this.selected.offerRecords.map((c) => {
          return {
            laborRate:
              this.selected.sourceUserDefaultSellRates[
                this.getLaborPropByLaborEnum(c.sourceLaborType)
              ],
            laborType: c.sourceLaborType,
          };
        }),
        "laborType"
      );
      this.targetSellRates.laborRatePerLaborTypes = uniqBy(
        this.selected.offerRecords.map((c) => {
          return {
            laborRate:
              this.selected.targetUserDefaultSellRates[
                this.getLaborPropByLaborEnum(c.targetLaborType)
              ],
            laborType: c.targetLaborType,
          };
        }),
        "laborType"
      );
    },
    getLaborPropByLaborEnum(laborType) {
      switch (laborType) {
        case enums.LABOR_TYPE.AC.value:
          return "ac";
          break;
        case enums.LABOR_TYPE.BIM.value:
          return "bim";
          break;
        case enums.LABOR_TYPE.C.value:
          return "c";
          break;
        case enums.LABOR_TYPE.MGT.value:
          return "management";
          break;
        case enums.LABOR_TYPE.SC.value:
          return "sc";
          break;
        case enums.LABOR_TYPE.SCD.value:
          return "scd";
          break;
        case enums.LABOR_TYPE.TCN.value:
          return "technical";
          break;
        case enums.LABOR_TYPE.VI.value:
          return "vi";
          break;
      }
      return null;
    },
    fetchOffer() {
      this.isFetching = true;
      offersAPI
        .getByIdForReview(this.offerId)
        .then((resp) => {
          this.$log("getOfferById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.review.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.prepareOfferReview();
        })
        .catch((err) => {
          this.$log("getOfferById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    approveOffer() {
      this.modals.approve.note = null;
      this.modals.approve.loading = false;
      this.modals.approve.active = true;
    },
    approveOfferConfirmed() {
      this.modals.approve.loading = true;
      offersAPI
        .approveOffer({
          id: this.selected.id,
          note: this.modals.approve.note,
          offerRecords: this.selected.offerRecords,
          sourceSellRates: this.sourceSellRates,
          targetSellRates: this.targetSellRates,
        })
        .then((resp) => {
          this.modals.approve.loading = false;
          this.onApproveOfferSuccess(this.selected.id);
          this.approveOfferDiscard();
          this.$dialog.notify.success(`Offer <b>'${this.selected.name}'</b> Approved!`, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((resp) => {
          this.modals.approve.loading = false;
          this.$dialog.notify.error("Error approving the contract!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    approveOfferDiscard() {
      this.modals.approve.note = null;
      this.modals.approve.loading = false;
      this.modals.approve.active = false;
    },
    onApproveOfferSuccess(id) {
      this.$emit("approve", id);
    },
    rejectOffer() {
      this.modals.reject.note = null;
      this.modals.reject.loading = false;
      this.modals.reject.active = true;
    },
    rejectOfferConfirmed() {
      this.modals.reject.loading = true;
      offersAPI
        .rejectOffer({
          id: this.selected.id,
          note: this.modals.reject.note,
        })
        .then((resp) => {
          this.modals.reject.loading = false;
          this.onRejectOfferSuccess(this.selected.id);
          this.rejectOfferDiscard();
          this.$dialog.notify.success(`Offer <b>'${this.selected.name}'</b> Rejected!`, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((resp) => {
          this.modals.reject.loading = false;
          this.$dialog.notify.error("Error approving the contract!", {
            position: "top-right",
            timeout: 3000,
          });
        });
    },
    rejectOfferDiscard() {
      this.modals.reject.note = null;
      this.modals.reject.loading = false;
      this.modals.reject.active = false;
    },
    onRejectOfferSuccess(id) {
      this.$emit("reject", id);
    },
  },
};
</script>
